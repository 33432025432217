<template>
  <div id="week-swiper">
    <div
      v-for="(searchFlightItem, searchFlightIndex) of searchFlightArray"
      :key="searchFlightIndex"
    >
      <div class="d-flex-center my-25 gap-1 font-small-5 fw-700">
        <span
          v-if="!isMobileView"
          class="font-weight-bold"
        >{{ $t('flight.flightSegment') }}:</span>
        <span>
          {{ getAirportByAirportCode(searchFlightItem.startPoint)?.name }} - {{ getAirportByAirportCode(searchFlightItem.endPoint)?.name }}, {{ convertISODateTimeLangVN(searchFlightItem.departDate).dayWithMonth }}
        </span>
      </div>

      <swiper
        v-if="cheapestFare && visibleCheapestFare"
        id="slider-component"
        ref="swiperRef"
        class="swiper-multiple"
        :class="`${visibleCheapestFare ? 'visible' : 'hidden'}`"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(item, index) in cheapestFare[searchFlightIndex]"
          :key="index"
          class="slide"
          style="height: auto; width: auto !important"
        >
          <b-card
            no-body
            :class="`mb-0 d-flex-center justify-content-start mr-0 px-0 h-100 btn font-weight-bold py-25
          ${
              searchFlightDateArray[searchFlightIndex] === item.date
                ? 'border-warning'
                : 'border-transparent'
            }`"
            :style="`${
              new Date().getTime() - new Date(item.date).getTime() >
              24 * 60 * 60 * 1000
                ? 'cursor: not-allowed; opacity: 40%; color: #777;'
                : ''
            }
          ${
              searchFlightDateArray[searchFlightIndex] === item.date
                ? 'background-color: #FFF8E7'
                : ''
            }; border-radius: 0px; border-left: 1px dashed #ccc; border-right: 1px dashed #ccc; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;`"
            @click="() => {
              if (new Date().getTime() - new Date(item.date).getTime() < 24 * 60 * 60 * 1000)
                handleClick(item.date, searchFlightIndex, index)
            }"
          >
            <h5
              :class="`fw-800 text-center mb-0 ${ searchFlightDateArray[searchFlightIndex] === item.date ? 'text-dark' : '' }`"
            >
              {{ `${convertISODateTimeToLLLL(item.date).weekday}, ${convertISODateTimeToLLLL(item.date).dayAndMonth}` }}
            </h5>
          </b-card>
        </swiper-slide>

        <!-- ANCHOR Arrows -->
        <div
          slot="button-prev"
          class="swiper-button-prev"
        >
          <b-button
            variant="white"
            class="rounded-circle border d-flex-center p-0"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="text-body"
              size="24"
            />
          </b-button>
        </div>

        <div
          slot="button-next"
          class="swiper-button-next"
        >
          <b-button
            variant="white"
            class="rounded-circle border d-flex-center p-0"
          >
            <feather-icon
              icon="ChevronRightIcon"
              class="text-body"
              size="24"
            />
          </b-button>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'

import { isDomesticFlight } from '@/constants/selectOptions'
import store from '@/store'

import {
  convertISODateTime,
  formatCurrency,
  formatDateMonth,
  getArrayDatesBetween,
  convertISODateTimeToLLLL,
  resolveBorderColorByAirline,
  convertISODateTimeLangVN,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BCard,
    BButton,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      searchFlightv2,
      getAirportByAirportCode,
      getSearchFlightArray: searchFlightArray,
    } = useBookingHandle()

    const DAY_RANGE = 7
    const initialSlideArray = ref([])
    const swiperRef = ref()

    const swiperOptions = computed(() => ({
      // freeMode: true,
      gridMode: true,
      centeredSlides: true,
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
        540: {
          slidesPerView: 4,
          slidesPerGroup: 1,
        },
        640: {
          slidesPerView: 5,
          slidesPerGroup: 1,
        },
        960: {
          slidesPerView: 6,
          slidesPerGroup: 1,
        },
        1200: {
          slidesPerView: 7,
          slidesPerGroup: 1,
        },
      },
      spaceBetween: 0,
      initialSlide: DAY_RANGE,
    }))

    const searchFlightDateArray = computed(() => store.getters[
      `${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`
    ]().map(i => formatDateMonth(i.departDate, 'YYYY-MM-DD')))

    const cheapestFare = ref([])
    const visibleCheapestFare = ref()

    async function fetchCheapFare() {
      visibleCheapestFare.value = JSON.parse(localStorage.getItem('visibleCheapestFare') || 'true')
      cheapestFare.value = []
      const searchFlightDateArray = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]()
      searchFlightDateArray.forEach((item, index) => {
        const departDate = new Date(item.departDate)
        const startDate = new Date(
          departDate.getFullYear(),
          departDate.getMonth(),
          departDate.getDate() - DAY_RANGE,
        )
        const endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + DAY_RANGE * 2,
        )
        cheapestFare.value[index] = getArrayDatesBetween(
          formatDateMonth(startDate, 'YYYY-MM-DD'),
          formatDateMonth(endDate, 'YYYY-MM-DD'),
        )
      })

      cheapestFare.value.forEach((cheapItem, cheapIndex) => {
        const depDate = new Date(searchFlightDateArray[cheapIndex].departDate)
        const departureDate = convertISODateTime(depDate).dateFilter
        cheapItem.forEach((item, itemIndex) => {
          if (item.date === departureDate) {
            initialSlideArray.value[cheapIndex] = itemIndex
          }
        })
      })
    }

    onMounted(() => {
      fetchCheapFare()
    })

    // search lại thì call lại fetchCheapFare
    watch(
      searchFlightDateArray,
      () => {
        fetchCheapFare()
      },
      { deep: true },
    )

    const handleClick = (date, searchFlightIndex, index) => {
      initialSlideArray.value[searchFlightIndex] = index
      const searchFlightPayload = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`]
      if (searchFlightPayload.type === 'OW') {
        searchFlightPayload.flights[0].departDate = date
      } else if (searchFlightPayload.type === 'RT') {
        if (searchFlightIndex === 0) searchFlightPayload.flights[0].departDate = date
        else if (searchFlightIndex === 1) {
          searchFlightPayload.flights[0].returnDate = date
        }
      } else if (searchFlightPayload.type === 'MC') {
        searchFlightPayload.flights[searchFlightIndex].departDate = date
      }
      searchFlightv2(searchFlightPayload, true)
    }

    return {
      convertISODateTime,
      formatCurrency,
      searchFlightDateArray,
      handleClick,
      cheapestFare,
      convertISODateTimeToLLLL,
      swiperOptions,
      visibleCheapestFare,
      resolveBorderColorByAirline,
      swiperRef,

      isDomesticFlight,
      searchFlightArray,
      getAirportByAirportCode,
      convertISODateTimeLangVN,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

.swiper-button-prev {
  margin-top: 0;
  translate: -30% -50%;

  &:after {
    background-image: unset !important;
  }
}

.swiper-button-next {
  margin-top: 0;
  translate: 60% -50%;

  &:after {
    background-image: unset !important;
  }
}

#week-swiper ::v-deep {
  .swiper-wrapper {
    margin-right: 8px;
    margin-left: 0px;
  }
}
</style>
